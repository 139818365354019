import { Box, Checkbox, Divider } from "@mui/material";

interface MissionsProps {
  chapter: number;
}

function Missions({ chapter }: MissionsProps) {
  const missionsByChapter: {
    [key: number]: { id: number; text: string; isChecked: boolean }[];
  } = {
    0: [
      {
        id: 1,
        text: "Hit the newspaper and spread the word about financial bandits",
        isChecked: true,
      },
      {
        id: 2,
        text: "Gather our members and interact with them",
        isChecked: true,
      },
      { id: 3, text: "Launch an updated website", isChecked: true },
      { id: 4, text: "Publish Klever Hackathon proposal", isChecked: true },
    ],
    1: [
      { id: 1, text: "Setting up our camp", isChecked: true },
      { id: 2, text: "Welcome our members to the camp", isChecked: true },
      {
        id: 3,
        text: "Starting the discovery of our first town",
        isChecked: true,
      },
    ],
    2: [
      { id: 1, text: "Placeholder chapter 2", isChecked: false },
      { id: 2, text: "Placeholder chapter 2", isChecked: false },
      { id: 3, text: "Placeholder chapter 2", isChecked: false },
    ],
  };

  const missions = missionsByChapter[chapter] || [];

  return (
    <Box>
      {missions.map((mission) => (
        <div>
          <div className="flex flex-row mt-2 mb-2" key={mission.id}>
            <div>
              <Checkbox checked={mission.isChecked} disabled />
            </div>
            <div className="flex items-center text-lg tracking-wider text-white select-none sm:text-xl font-secondary">
              {mission.text}
            </div>
          </div>
          <Divider variant="middle" style={{ height: "1px", margin: 0 }} />
        </div>
      ))}
    </Box>
  );
}

export default Missions;
