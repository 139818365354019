import { Sparkle } from "lucide-react";

const Divider: React.FC = () => {
  return (
    <div className="my-6">
      <div className="flex flex-row items-center">
        <div className=" h-[1px] w-1/2 bg-primary opacity-80"></div>
        <Sparkle className="w-4 h-4 mx-2 opacity-80 text-primary" />
        <div className=" h-[1px] w-1/2 bg-primary opacity-80"></div>
      </div>
    </div>
  );
};
export default Divider;
