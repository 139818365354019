import Divider from "../../Layout/Divider";

const Camp: React.FC = () => {
  return (
    <div>
      <div className="flex flex-col mb-6">
        <h1 className="mb-2 text-4xl font-thin tracking-wide text-left text-black select-none font-primary">
          World
        </h1>

        <div className="box-border flex flex-col">
          <div className="hover:opacity-95 hover:cursor-pointer">
            <a
              href="https://app.financialbandits.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="border-4 border-darkBrown">
                <img
                  src="./financial-bandits-map.png"
                  alt="financial bandits map"
                ></img>
              </div>
              <span className="flex flex-row items-center w-full gap-2 px-3 py-2 bg-darkBrown ">
                <p className="text-3xl text-white select-none font-secondary">
                  Camp
                </p>
                <p className="select-none text-primary font-secondary font-bold text-xs md:text-[1vw] translate-y-[-6px] md:translate-y-[-5px] uppercase">
                  New
                </p>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="xl:hidden">
        <Divider />
      </div>
    </div>
  );
};
export default Camp;
