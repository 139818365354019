import Chapters from "../components/chapters/chapters";
import FAQ from "../components/faq";
import Gang from "../components/gang/gang";
import Header from "../components/header/header";
import Story from "../components/story/index";
import Camp from "../components/world/camp";
import Divider from "../components/Layout/Divider";

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col justify-center mt-28 mb-28">
      <div className="mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-screen">
        <Header />
      </div>
      <div className="mx-[15%] xs:mx-[10%] mt-14">
        <div className="xl:space-y-0 xl:grid xl:grid-cols-2 xl:gap-x-4 xl:gap-y-0 xl:auto-cols-auto xl:auto-rows-auto">
          <div className="h-fit">
            <Camp />
          </div>
          <div className="grid h-full col-start-2 row-span-3 w-fit">
            <Story />
          </div>
          <Chapters />
        </div>
        <div className="xs:hidden xl:block">
          <Divider />
        </div>
        <Gang />

        <FAQ />
      </div>
    </div>
  );
};
export default HomePage;
