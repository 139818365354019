import Footer from "../components/footer/footer";
import Navigation from "../components/navigation/index";
import HomePage from "../pages";

const PageLayout = () => {
  return (
    <div className="flex flex-col w-full h-full bg-tertiary">
      <Navigation />
      <div className="items-center w-full h-full min-h-screen bg-tertiary">
        <HomePage />
      </div>
      <Footer />
    </div>
  );
};
export default PageLayout;
