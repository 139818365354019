import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Divider as MUIDivider } from "@mui/material";
import { CHAPTER_COLOR } from "../../constants/colors";

import React from "react";
import Mission from "../missions/mission";
import { ExpandIcon } from "../../constants/icons/expandIcon";
import { LockIcon } from "../../constants/icons/lockIcon";
import Divider from "../Layout/Divider";

function Chapters() {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <div>
        <h1 className="mb-2 text-4xl font-thin tracking-wide text-left text-black select-none font-primary">
          Chapters
        </h1>

        {/** 00 */}
        <Accordion
          expanded={expanded === "panel0"}
          onChange={handleChange("panel0")}
          elevation={5}
          sx={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls="panel0bh-content"
            id="panel0bh-header"
            sx={{
              backgroundColor: CHAPTER_COLOR,
            }}
          >
            <h2 className="flex pr-4 text-4xl font-thin tracking-wider text-white shrink font-primary">
              00
            </h2>
            <h2 className="flex items-end pr-4 text-2xl font-thin tracking-wider text-white shrink font-primary">
              Kickoff
            </h2>
          </AccordionSummary>
          <AccordionDetails className="bg-secondary">
            <Mission chapter={0} />
          </AccordionDetails>
        </Accordion>
        <MUIDivider />
        {/** 01 */}

        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          elevation={5}
          sx={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: CHAPTER_COLOR,
            }}
          >
            <h2 className="flex pr-4 text-4xl font-thin tracking-wider text-white shrink font-primary">
              01
            </h2>
            <h2 className="flex items-end pr-4 text-2xl font-thin tracking-wider text-white shrink font-primary">
              saddle up for fortune
            </h2>
          </AccordionSummary>
          <AccordionDetails className="bg-secondary">
            <Mission chapter={1} />
          </AccordionDetails>
        </Accordion>
        <MUIDivider />
        {/** 02 */}

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          elevation={5}
          disabled={true}
          sx={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <AccordionSummary
            expandIcon={<LockIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{
              backgroundColor: CHAPTER_COLOR,
            }}
          >
            <h2 className="flex pr-4 text-2xl font-thin tracking-wider text-white shrink font-primary">
              02
            </h2>
            <h2 className="flex pr-4 text-2xl font-thin tracking-wider text-white shrink font-primary">
              Claiming the Mother Lode
            </h2>
          </AccordionSummary>
          <AccordionDetails className="bg-secondary">
            <Mission chapter={2} />
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="xl:hidden">
        <Divider />
      </div>
    </div>
  );
}

export default Chapters;
