import { Icon } from "@iconify/react";
import { Sparkle } from "lucide-react";
import Divider from "../Layout/Divider";
const Gang: React.FC = () => {
  return (
    <div>
      <div className="w-full">
        <h1 className="mb-2 text-4xl font-thin tracking-wide text-left text-black select-none font-primary">
          OUR GANG
        </h1>
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row">
          {/*Ruben*/}
          <div className="flex w-full">
            <div className="w-full p-4 bg-black border-2 border-solid rounded-md sm:mr-2 h-fit border-primary">
              <div className="flex flex-col items-center align-center">
                <Icon
                  className="mb-[4px] w-12 h-12 sm:h-14 sm:w-14 md:h-16 md:w-16 lg:h-20 lg:w-20 text-primary"
                  icon="game-icons:bandit"
                />
                <h3 className="text-2xl font-thin tracking-wide text-white select-none font-primary">
                  Ruben Heye
                </h3>
                <h4 className="text-xl font-thin tracking-wider select-none font-primary text-primary">
                  Gang Leader
                </h4>
                <div className="flex flex-row items-center">
                  <a
                    className="text-xl underline font-secondary decoration-1 text-quaternary hover:text-primary active:text-primary"
                    href="https://www.linkedin.com/in/rubenheye/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                  <Sparkle className="w-4 h-4 mx-2 text-quaternary" />
                  <a
                    className="text-xl underline font-secondary decoration-1 text-quaternary hover:text-primary active:text-primary"
                    href="https://www.instagram.com/rubenheye/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                  <Sparkle className="w-4 h-4 mx-2 text-quaternary" />
                  <a
                    className="text-xl underline font-secondary decoration-1 text-quaternary hover:text-primary active:text-primary"
                    href="https://twitter.com/KleverRuben"
                    target="_blank"
                    rel="noreferrer"
                  >
                    X
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*Nino*/}
          <div className="flex w-full">
            <div className="w-full p-4 bg-black border-2 border-solid rounded-md sm:ml-2 h-fit border-primary">
              <div className="flex flex-col items-center align-center">
                <Icon
                  className="mb-[4px] w-12 h-12 sm:h-14 sm:w-14 md:h-16 md:w-16 lg:h-20 lg:w-20 text-primary"
                  icon="game-icons:bandit"
                />
                <h3 className="text-2xl font-thin tracking-wider text-white select-none font-primary">
                  Nino De Kerpel
                </h3>
                <h4 className="text-xl font-thin tracking-wider select-none font-primary text-primary">
                  Gang Leader
                </h4>
                <div className="flex flex-row items-center">
                  <a
                    className="text-xl underline font-secondary decoration-1 text-quaternary hover:text-primary active:text-primary"
                    href="https://www.linkedin.com/in/ninodekerpel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Linkedin
                  </a>
                  <Sparkle className="w-4 h-4 mx-2 text-quaternary" />
                  <a
                    className="text-xl underline font-secondary decoration-1 text-quaternary hover:text-primary active:text-primary"
                    href="https://www.instagram.com/ninodekerpel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                  <Sparkle className="w-4 h-4 mx-2 text-quaternary" />

                  <a
                    className="text-xl underline font-secondary decoration-1 text-quaternary hover:text-primary active:text-primary"
                    href="https://twitter.com/ninodekerpel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    X
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default Gang;
