const FAQ: React.FC = () => {
  return (
    <div className="w-full xl:mt-10">
      <h1 className="mb-2 text-4xl font-thin tracking-wide text-left text-black select-none font-primary">
        Frequently asked questions
      </h1>
      <div className="flex flex-col justify-between xl:space-x-16 xl:flex-row">
        <div className="xl:w-1/3">
          <p className="mb-6 text-xl xl:text-2xl text-primary font-secondary">
            Can’t find the answer you’re looking for? Reach out to our discord
            server!
          </p>
        </div>
        <div className="xl:w-2/3">
          <div className="flex flex-col space-y-4">
            <span className="font-secondary">
              <p className="text-2xl text-black xl:text-2xl">
                What is{" "}
                <p className="inline-block text-primary">Financial Bandits</p>,
                and what does it offer?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Financial Bandits is a platform on the Klever Blockchain that
                introduces a new staking mechanism to optimize node settings for
                maximum earnings. The platform allows users to purchase town
                coins, representing partial ownership in Financial Bandits nodes
                on the Klever Blockchain, and earn rewards when these nodes are
                elected.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                How are <p className="inline-block text-primary">rewards</p>{" "}
                distributed{" "}
                <p className="inline-block text-primary"> among users</p>?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                When a user contributes to reaching the goal of 10 million KLV
                for a specific node by purchasing town coins, they become
                eligible to earn a share of the rewards generated by that node.
                The number of town coins owned by a user reflects their share of
                earnings.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                What are the <p className="inline-block text-primary">fees</p>{" "}
                when purchasing town coins?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Financial Bandits charges a fee of 1% when purchasing town
                coins. This fee, included in the purchase price, ensures the
                platform's safety, uninterrupted operation, and healthy
                functionality.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                Can users{" "}
                <p className="inline-block text-primary">trade town coins</p> on
                a DEX or decentralized exchange?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Yes, town tokens will be tradable when a decentralized exchange
                (DEX) becomes available on the Klever Blockchain. If a DEX is
                not available at the time of Klever's changes, Financial Bandits
                commits to creating one for all town tokens.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                <p className="inline-block text-primary">When</p> will the
                ability to sell town coins be{" "}
                <p className="inline-block text-primary">available?</p>
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                The ability to sell town coins will be introduced when the
                Klever team implements necessary functionalities, anticipated to
                be in Q1 2024. Financial Bandits is actively working on this
                feature to provide users with a comprehensive experience.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                <p className="inline-block text-primary">What is a Town Coin</p>
                , and how does it contribute to the Financial Bandits platform?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                A Town Coin is a digital asset created for each node or town on
                the Klever Blockchain within the Financial Bandits ecosystem.
                Purchasing Town Coins helps fund the launch of a node, allowing
                users to participate in this specific node and earn rewards.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                How much is a{" "}
                <p className="inline-block text-primary">Town Coin worth</p>,
                and what is its significance in staking?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Each Town Coin has a value of 1000 KLV, adhering to the maximum
                allocation rule of 10 million KLV per node (10000 max supply).
                It represents a user's share in a specific node and entitles
                them to a portion of the rewards generated by that node when it
                gets elected.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                How are <p className="inline-block text-primary">rewards</p>{" "}
                distributed <p className="inline-block text-primary">among</p>{" "}
                Town Coin <p className="inline-block text-primary">holders</p>?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Town Coin holders earn a share of rewards whenever the
                associated node gets elected on the Klever Blockchain. The
                number of Town Coins owned by a user determines their share in
                the earnings, reflecting a proportional contribution to the FPR
                staking pool.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                Is there a <p className="inline-block text-primary">limit</p> to
                the number of Town Coins I can purchase for a specific town?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                There is no specified limit on the number of Town Coins a user
                can purchase for a specific town. However, the total supply of
                Town Coins for each town is capped at 10,000.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                How often can I expect rewards from my Town Coin holdings?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Rewards are generated whenever the associated node/town is
                elected on the Klever Blockchain. The frequency of rewards is
                dependent on the election cycles, and users can expect periodic
                payouts based on the performance of the staked node.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                <p className="inline-block text-primary">
                  What is the ORBAY token
                </p>
                , and how does it differ from other Town Coins?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                ORBAY is a special token associated with the first town, Origin
                Bay, on the Financial Bandits platform. Participants in the
                first town not only earn rewards from Origin Bay but also
                receive a 10% share of team earnings across the entire platform.
                ORBAY is more than an investment in a specific node; it
                represents a stake in the success of the entire Financial
                Bandits platform.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                How are{" "}
                <p className="inline-block text-primary">
                  team earnings distributed
                </p>
                , and what constitutes team earnings?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                Team earnings refer to the overall rewards generated by all
                discovered towns on the Financial Bandits platform, excluding
                the first town, Origin Bay. Participants in Origin Bay receive a
                10% share of these team earnings, providing a continuous source
                of income beyond the specific node they initially invested in.
              </p>
            </span>
            <span className="font-secondary">
              <p className="text-2xl text-black">
                How does the{" "}
                <p className="inline-block text-primary">10% share</p> in team
                earnings benefit ORBAY token holders?
              </p>
              <p className="text-lg tracking-wide xl:text-xl text-secondary">
                ORBAY token holders receive a continuous income stream in the
                form of a 10% share of team earnings across all discovered towns
                after Origin Bay. This feature ensures ongoing rewards and
                aligns participants with the overall success and expansion of
                the Financial Bandits ecosystem.
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
