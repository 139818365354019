import Divider from "../Layout/Divider";

const Story: React.FC = () => {
  return (
    <div>
      <div className="">
        <h1 className="mb-2 text-4xl font-thin tracking-wide text-left text-black select-none font-primary">
          Story
        </h1>
        <div className="p-4 border-2 border-dotted bg-quaternary border-secondary">
          <span className="mt-0 space-y-4 text-lg tracking-wider text-white select-none sm:text-xl md:text-2xl font-secondary">
            <p>
              {" "}
              <p className="inline-block text-5xl text-black">In 1978</p> we
              were invited by Scott Stornetta and Stuart Haber, the inventors of
              the so called{" "}
              <p className="inline-block text-3xl text-black">"Blockchain"</p>.
              Scott came up with the idea because he witnessed a scandal. Some
              scientific papers got forged! He decided to invent a system to
              tackle the problem of immutability of digital records. After a
              detailed explanation of how it all works, we got back to our
              hotel. We could not stop thinking about it, So we started talking
              about other possible use cases of the technology. It hit us! We
              wanted to fight the traditional banks and disrupt their system!
              After sketching and planning everything out we headed over to the
              bar to celebrate.
            </p>
            <p className="inline-block text-black">
              A couple rounds of beers later ... some strange people came up to
              us.
            </p>
            <p>
              They asked us to come outside for a minute. We acted though so
              went along with it. From that moment on{" "}
              <p className="inline-block text-black">it all went dark...</p> Not
              because of the beers.
            </p>
            <p>
              The first time we opened our eyes again, we couldn't believe it,
              but it looked like the Wild West again! All we can remember is
              that night meeting up with Scott and Stuart, and heading over to
              the bar. Strange right?
            </p>
            <p>
              <p className="inline-block text-black">We built a camp</p> to
              saddle up for fortune because this is not over yet!
            </p>
          </span>
        </div>
      </div>
      <div className="xl:hidden">
        <Divider />
      </div>
    </div>
  );
};

export default Story;
