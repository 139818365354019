const Header: React.FC = () => {
  return (
    <div className="max-w-full text-center xs:w-full">
      <h1 className="mb-0 text-4xl font-thin tracking-wider select-none font-primary text-primary sm:text-5xl lg:text-6xl">
        Financial Bandits
      </h1>
      <h2 className="font-primary text-black tracking-wider mt-2.5 text-xl font-thin sm:text-3xl lg:text-4xl select-none">
        Contribute, Share, Thrive!
      </h2>
    </div>
  );
};

export default Header;
