import { Disclosure } from "@headlessui/react";

import { ExternalLink, Menu, TentTree, X } from "lucide-react";

const navigation = [
  { name: "Visit camp", href: "https://app.financialbandits.com" },
  {
    name: "Hackathon proposal",
    href: "https://medium.com/@financialbandits/klever-hackathon-2023-financial-bandits-b1c58466173f",
  },
];

const Navigation: React.FC = () => {
  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="box-border fixed top-0 z-40 block w-full h-16 bg-tertiary drop-shadow">
            <div className="block w-full h-full mx-auto max-w-screen">
              <div className="relative flex items-center justify-between h-full">
                <div className="absolute inset-y-0 right-0 flex items-center md:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 m-2 bg-transparent ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <X
                        className="block w-6 h-6 text-primary "
                        aria-hidden="true"
                      />
                    ) : (
                      <Menu
                        className="block w-6 h-6 text-primary "
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex items-center justify-between flex-1 place-items-center">
                  <div className="h-full md:pr-1 md:justify-items-start ">
                    <div className="flex ml-2.5 mt-0.5 relative box-border">
                      <span className="w-full px-2 py-1 text-xl antialiased font-thin tracking-wide text-white uppercase md:text-2xl border-box bg-primary font-primary">
                        Financial Bandits
                      </span>
                    </div>
                  </div>
                  <div className="relative hidden mr-5 overflow-auto md:ml-6 md:block">
                    <div className="min-w-0 mx-auto max-w-fit md:max-w-full md:min-w-full">
                      <div className="flex justify-end space-x-4 overflow-x-auto">
                        <span className="px-4 py-0.5 space-x-2 flex items-center align-middle  font-thin tracking-wide text-white uppercase font-secondary bg-secondary hover:bg-primary hover:text-white xs:text-lg whitespace-nowrap">
                          <a
                            href="https://app.financialbandits.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p className="text-base sm:text-lg">Visit Camp</p>
                          </a>
                          <TentTree className="w-4 h-4" />
                        </span>
                        <span className="px-4 py-0.5 space-x-2 flex items-center align-middle text-lg font-thin tracking-wide text-white uppercase font-secondary bg-secondary hover:bg-primary hover:text-white xs:text-lg whitespace-nowrap">
                          <a
                            href="https://medium.com/@financialbandits/klever-hackathon-2023-financial-bandits-b1c58466173f"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p className="text-base sm:text-lg">
                              Hackathon Proposal
                            </p>
                          </a>
                          <ExternalLink className="w-4 h-4" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="box-border fixed z-30 block w-full h-full mx-auto bg-darkBrown bg-opacity-60 top-16 md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Disclosure.Button
                target="_blank"
                rel="noreferrer"
                key={navigation[0].name}
                as="a"
                href={navigation[0].href}
                className="text-lg font-thin tracking-wide text-white uppercase sm:text-xl font-secondary px-4 py-0.5 space-x-2 flex items-center align-middle bg-secondary hover:bg-primary hover:text-white xs:text-lg whitespace-nowrap"
              >
                <p>{navigation[0].name}</p>
                <TentTree className="w-4 h-4 text-white" />
              </Disclosure.Button>
              <Disclosure.Button
                target="_blank"
                rel="noreferrer"
                key={navigation[1].name}
                as="a"
                href={navigation[1].href}
                className="text-lg font-thin tracking-wide text-white uppercase sm:text-xl font-secondary px-4 py-0.5 space-x-2 flex items-center align-middle bg-secondary hover:bg-primary hover:text-white xs:text-lg whitespace-nowrap"
              >
                <p>{navigation[1].name}</p>
                <ExternalLink className="w-4 h-4 text-white" />
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;
